import _createForOfIteratorHelper from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _typeof from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/web.dom-collections.for-each.js";
import store from "../store";
import $ from 'jquery';
import { successColor, brandColor, defaultFadeInTimeout, logCssColors, logCssErrorColors, avatarColorPresets, avatarColors, paidInvoiceColor, unpaidInvoiceColor } from './uiconstants';
import moment from 'moment';
import Vue from 'vue';
import { InvoiceStatusType } from "@/enum/invoiceStatusType";
import { months } from "./constants";
export var getCookie = function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length >= 2) return parts.pop().split(";").shift();
};
export var openOverlay = function openOverlay(id) {
  $("#overlay").fadeIn(defaultFadeInTimeout);
  $("#".concat(id)).fadeIn(defaultFadeInTimeout);
  $('body').css({
    height: "100%",
    overflow: "hidden"
  });
};
export var openOverlayPermanent = function openOverlayPermanent(id) {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  $("#overlayPermanent").fadeIn(defaultFadeInTimeout);
  $("#".concat(id)).fadeIn(defaultFadeInTimeout);
  $('body').css({
    height: "100%",
    overflow: "hidden"
  });
};
export var closeOverlay = function closeOverlay(elementId) {
  if ($('#' + elementId).length > 0) {
    $("#overlay").fadeOut(defaultFadeInTimeout);
    $("#".concat(elementId)).fadeOut(defaultFadeInTimeout);
    $('body').css({
      height: "auto",
      overflow: "unset"
    });
  }
};
export var closeOverlayPermanent = function closeOverlayPermanent(elementId) {
  if ($('#' + elementId).length > 0) {
    $("#overlayPermanent").fadeOut(defaultFadeInTimeout);
    $("#".concat(elementId)).fadeOut(defaultFadeInTimeout);
    $('body').css({
      height: "auto",
      overflow: "unset"
    });
  }
};
export var checkFieldsValidity = function checkFieldsValidity(fields) {
  var allFieldsValid = true;
  fields.map(function (field) {
    if (!(field != null && field !== '')) {
      allFieldsValid = false;
    }
  });
  if (allFieldsValid) return true;else return false;
};
export var notify = function notify(msg, result, callbackObject) {
  var _a;
  var messageToDisplay;
  if (typeof msg === "string") {
    messageToDisplay = ((_a = Vue === null || Vue === void 0 ? void 0 : Vue.prototype) === null || _a === void 0 ? void 0 : _a.$stringConstants(msg)) || msg;
  } else if (_typeof(msg) === "object") {
    messageToDisplay = msg.message;
  }
  store.commit('setNotification', {
    title: msg === null || msg === void 0 ? void 0 : msg.title,
    message: messageToDisplay,
    callback: (callbackObject === null || callbackObject === void 0 ? void 0 : callbackObject.callback) || '',
    actionText: (callbackObject === null || callbackObject === void 0 ? void 0 : callbackObject.text) || "Okay",
    type: result
  });
};
export var closeNotify = function closeNotify() {
  var timeout = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  setTimeout(function () {
    store.commit('cleanUpNotification');
  }, timeout);
};
export var notifySnackbar = function notifySnackbar(msg, callbackObject) {
  store.commit('setNotification', {
    message: msg,
    callback: callbackObject === null || callbackObject === void 0 ? void 0 : callbackObject.callback,
    actionText: callbackObject === null || callbackObject === void 0 ? void 0 : callbackObject.text
  });
};
export var notifyAutoClose = function notifyAutoClose(msg, timeSpan) {
  var notiTitle = document.getElementsByClassName("model-heading-auto-close")[0];
  $(".modal").css({
    "background-color": "rgba(0,0,0,0.4)"
  });
  $(".modal-content").css({
    "width": "550px"
  });
  $("#notiAutoCloseModal").fadeIn(500);
  notiTitle.innerHTML = "";
  notiTitle.innerHTML = "Information";
  $('.modal-header').css({
    "background-color": brandColor
  });
  var notiMessage = document.getElementsByClassName("message-auto-close")[0];
  notiMessage.innerHTML = "";
  notiMessage.innerHTML = msg;
  setTimeout(function () {
    $("#notiAutoCloseModal").fadeOut(500);
  }, timeSpan);
};
export var notifyDev = function notifyDev(msg, result) {
  var notiTitle = document.getElementsByClassName("model-heading")[0];
  $(".modal").css({
    "background-color": "rgba(0,0,0,0.4)"
  });
  $(".modal-content").css({
    "width": "850px"
  });
  $("#notiModal").fadeIn(500);
  notiTitle.innerHTML = "";
  notiTitle.innerHTML = "Information";
  if (result === "success") {
    $('.modal-header').css({
      "background-color": successColor
    });
  } else if (result === "fail") {
    notiTitle.innerHTML = "";
    notiTitle.innerHTML = "Error";
    $('.modal-header').css({
      "background-color": '#000000'
    });
    $('.noti-title').text("Error");
  } else {
    $('.modal-header').css({
      "background-color": brandColor
    });
  }
  var notiMessage = document.getElementsByClassName("message")[0];
  notiMessage.innerHTML = "";
  notiMessage.innerHTML = msg;
};
export var getPolygonCoords = function getPolygonCoords(polygonPath) {
  var x = 0.0;
  var y = 0.0;
  var z = 0.0;
  var polygonCoords = [];
  var initialCoords;
  var i = 0;
  var _iterator = _createForOfIteratorHelper(polygonPath),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var coord = _step.value;
      var lat = coord.latitude * Math.PI / 180;
      var lng = coord.longitude * Math.PI / 180;
      if (i == 0) {
        initialCoords = {
          "lat": coord.latitude,
          "lng": coord.longitude
        };
        i = 1;
      }
      polygonCoords.push({
        "lat": coord.latitude,
        "lng": coord.longitude
      });
      x += Math.cos(lat) * Math.cos(lng);
      y += Math.cos(lat) * Math.sin(lng);
      z += Math.sin(lat);
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  polygonCoords.push(initialCoords);
  var total = polygonPath.length;
  x = x / total;
  y = y / total;
  z = z / total;
  return [x, y, z, polygonCoords];
};
export var createMarkerIcon = function createMarkerIcon(fillColor, scale) {
  var icon = {
    path: "M-30,0a30,30 0 2,0 60,0a30,30 0 2,0 -60,0",
    fillColor: fillColor,
    strokeColor: "#fff",
    fillOpacity: 1,
    anchor: new window.google.maps.Point(0, 0),
    strokeWeight: 1,
    scale: scale
  };
  return icon;
};
export var getUTCDateFormat = function getUTCDateFormat(date) {
  return moment(date).utc().format();
};
export var getHoursAndMins = function getHoursAndMins(timeDurationInSeconds) {
  var timeDurationInMinutes = Math.floor(timeDurationInSeconds / 60);
  var hours = Math.floor(timeDurationInMinutes / 60);
  var minutes = timeDurationInMinutes % 60;
  var remainingSeconds = timeDurationInSeconds % 60;
  if (remainingSeconds > 50 && minutes === 0) minutes = 1;
  var totalMinutes = hours * 60 + minutes;
  return {
    hours: hours,
    minutes: minutes,
    totalMinutes: totalMinutes
  };
};
export var getMinutesFromSeconds = function getMinutesFromSeconds(timeDurationInSeconds) {
  return Math.floor(timeDurationInSeconds / 60);
};
export var getHoursAndMinTimeSheet = function getHoursAndMinTimeSheet(time) {
  var hour = Math.floor(time / 60);
  var min = time % 60;
  return {
    hours: hour,
    minute: min
  };
};
export var getHoursAndMinsText = function getHoursAndMinsText(durationInMinutes) {
  var hours = Math.floor(durationInMinutes / 60);
  var minutes = durationInMinutes % 60;
  if (hours && minutes === 0) {
    return "".concat(hours, " hr").concat(hours > 1 ? 's' : '');
  } else if (hours === 0 && minutes) {
    return "".concat(minutes, " mins");
  } else if (hours && minutes) {
    return "".concat(hours, " hr").concat(hours > 1 ? 's' : '', " ").concat(minutes, " mins");
  } else {
    return 'N/A';
  }
};
export var getDateFormatted = function getDateFormatted(date) {
  return date ? moment.utc(date).local().format("DD MMM YYYY") : "-";
};
export var getDateWithoutTime = function getDateWithoutTime(date) {
  var dateIN = moment.utc(date).local().format('YYYY-MM-DD');
  return dateIN;
};
export var validateRegexAlphaNumeric = function validateRegexAlphaNumeric(event) {
  if (!/[a-zA-Z0-9\s]/.test(event.key)) {
    event.preventDefault();
  }
};
export var validateRegexAlpha = function validateRegexAlpha(event) {
  if (!/[a-zA-Z\s]/.test(event.key)) {
    event.preventDefault();
  }
};
export var validateRegexAlphaCaps = function validateRegexAlphaCaps(event) {
  if (!/[A-Z\s]/.test(event.key)) {
    event.preventDefault();
  }
};
export var validateRegexNumeric = function validateRegexNumeric(e) {
  if (!/[^-+]/.test(e.key)) {
    e.preventDefault();
  }
};
export var validateRegexPhoneNumber = function validateRegexPhoneNumber(event) {
  var regex = /^[+0-9\s]/;
  if (!regex.test(event.key) && event.keyCode !== 8) {
    event.preventDefault();
    return false;
  }
  return true;
};
export var getDateDifferenceInDays = function getDateDifferenceInDays(endDate) {
  var expiryDate = moment.utc(endDate).local().format();
  var currentDate = moment();
  return (-Math.round(moment.duration(currentDate.diff(expiryDate)).asDays())).toString();
};
export var getDateDifferenceInMinutes = function getDateDifferenceInMinutes(endDate) {
  var expiryDate = moment(endDate);
  var currentDate = moment();
  var dateDiff = (-Math.round(moment.duration(currentDate.diff(expiryDate)).asMinutes())).toString();
  return dateDiff;
};
export var beautifyTimespan = function beautifyTimespan(timeInSeconds) {
  if (timeInSeconds && timeInSeconds > 0) {
    var minsTemp = timeInSeconds / 60;
    var hours = Math.floor(minsTemp / 60);
    var mins = minsTemp % 60;
    if (hours != 0 && mins != 0) {
      if (mins >= 59) {
        hours += 1;
        return hours + " hr ";
      } else {
        return hours + " hr " + mins.toFixed(0) + " mins";
      }
    } else if (hours == 0 && mins != 0) {
      return mins.toFixed(0) + " mins";
    } else if (hours != 0 && mins == 0) {
      return hours + " hr";
    }
  } else {
    return '-';
  }
};
export var getCapitalised = function getCapitalised(unformattedString) {
  return unformattedString ? unformattedString.charAt(0).toUpperCase() + unformattedString.slice(1).toLowerCase() : '';
};
export var getAreaUnit = function getAreaUnit() {
  var convertedAreaUnit = null;
  switch (localStorage.getItem('defaultAreaMeasurementUnit')) {
    case '0':
      // Hectare
      convertedAreaUnit = 'ha';
      break;
    case '1':
      // Acre
      convertedAreaUnit = 'acre';
      break;
    default:
      convertedAreaUnit = 'Sq m';
      break;
  }
  return convertedAreaUnit;
};
export var getConvertedArea = function getConvertedArea(areaInSquareMeter) {
  var convertedArea;
  if (!areaInSquareMeter) return null;
  switch (localStorage.getItem('defaultAreaMeasurementUnit')) {
    case '0':
      //Hectare
      convertedArea = (areaInSquareMeter / 10000).toFixed(2);
      break;
    case '1':
      convertedArea = (areaInSquareMeter / 4047).toFixed(2);
      break;
    default:
      convertedArea = areaInSquareMeter.toFixed(2);
      break;
  }
  return convertedArea;
};
export var getConvertedAreaUnit = function getConvertedAreaUnit(areaInSquareMeter) {
  return (getConvertedArea(areaInSquareMeter) || 0) + ' ' + getAreaUnit();
};
export var convertToSquareMeters = function convertToSquareMeters(recievedArea) {
  var reverseConvertedArea = null;
  switch (localStorage.getItem('defaultAreaMeasurementUnit')) {
    case '0':
      // Hectare to sqm
      reverseConvertedArea = (recievedArea * 10000).toFixed(2);
      break;
    case '1':
      // Acre to sqm
      reverseConvertedArea = (recievedArea * 4047).toFixed(2);
      break;
  }
  return reverseConvertedArea;
};
export var getJobStatus = function getJobStatus(status) {
  switch (status) {
    case 0:
      return 'New';
    case 1:
      return 'Scheduled';
    case 2:
      return 'In-progress';
    case 3:
      return 'Paused';
    case 4:
      return 'Awaiting completion';
    case 5:
      return 'Part completed';
    case 6:
      return 'Completed';
    case 7:
      return 'Cancelled';
  }
};
export var parseFloatLocal = function parseFloatLocal(str, val) {
  str = str.toString();
  str = str.slice(0, str.indexOf(".") + val + 1);
  return Number(str);
};
export var parseFloatDecimal = function parseFloatDecimal(val) {
  return (Math.round(val * 100) / 100).toFixed(2);
};
export var getDateTimeFormat = function getDateTimeFormat(date) {
  return moment.utc(date).local().format("DD MMM YYYY");
};
export var getDateTimeFormattedLocal = function getDateTimeFormattedLocal(dateTime) {
  return dateTime ? moment.utc(dateTime).local().format('DD MMM YYYY, hh:mm A') : '';
};
export var deleteObjectFromList = function deleteObjectFromList(haystack, needle, property) {
  var needleIndex = haystack.findIndex(function (item) {
    return item[property] == needle;
  });
  if (needleIndex != -1) haystack.splice(needleIndex, 1);
  return haystack;
};
export var deleteObjectFromMachineList = function deleteObjectFromMachineList(haystack, needle, property) {
  var needleIndex = haystack.findIndex(function (item) {
    return item[property] == needle;
  });
  haystack.splice(needleIndex, 1);
  return haystack;
};
var _generateRandomHexColor = function generateRandomHexColor() {
  var randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
  if (randomColor.length != 7) {
    return _generateRandomHexColor();
  } else {
    return randomColor;
  }
};
export { _generateRandomHexColor as generateRandomHexColor };
export var isShortCodeLengthValid = function isShortCodeLengthValid(shortCode) {
  return shortCode && shortCode.length >= 2;
};
export var responseHasListData = function responseHasListData(result) {
  if (result && result.data && result.data.value) {
    return true;
  }
  return false;
};
export var getResponseData = function getResponseData(result) {
  if (result && result.data && result.data.value) {
    return result.data.value;
  }
  return [];
};
export var listContainsItem = function listContainsItem(list, listItemIdentifiers, itemIdToCheckFor) {
  function getItemsObject(listItem, listItemIdentifiers) {
    var items = 'listItem';
    listItemIdentifiers.forEach(function (item, index) {
      items += "[listItemIdentifiers[".concat(index, "]]");
    });
    return items;
  }
  if (list && list.length) {
    return list.find(function (listItem) {
      return eval("".concat("".concat(getItemsObject(listItem, listItemIdentifiers)))) == itemIdToCheckFor;
    });
  } else {
    return false;
  }
};
export var reduceText = function reduceText(text, maxTextLength) {
  if (text.length <= maxTextLength) return text;
  return text.slice(0, maxTextLength) + '..';
};
export var urltoFile = function urltoFile(url, filename, mimeType) {
  return fetch(url).then(function (res) {
    return res.arrayBuffer();
  }).then(function (buf) {
    return new File([buf], filename, {
      type: mimeType
    });
  });
};
export var getInitials = function getInitials(data) {
  if (data != null) {
    var chr = null;
    var d = data.replace(/[^a-zA-Z0-9]/g, " ");
    d = d.toUpperCase().replace(/\s\s+/g, " ");
    chr = d.trim().split(" ");
    if (chr.length >= 2) {
      return chr[0][0] + chr[1][0];
    } else {
      return d[0] + d[1];
    }
  }
  return "";
};
export var getCommaSeparatedQuery = function getCommaSeparatedQuery(queries, queryIdentifier) {
  if (queries) {
    return queries.trim().split(',').map(function (id) {
      return "".concat(queryIdentifier, "=").concat(id);
    }).join('&');
  } else {
    notify('Invalid query parameters passed!', 'fail');
  }
};
export var getShortName = function getShortName(str, size) {
  if (str) {
    if (str.length <= size) return str;
    return str.slice(0, size) + '..';
  }
  return '';
};
export var devLogger = function devLogger() {
  var isProd = process.env.NODE_ENV == 'production';
  return {
    log: function log(str) {
      if (isProd) return;
      console.log('%c%s', logCssColors, str);
    },
    logNewline: function logNewline(str) {
      if (isProd) return;
      console.log('%c%s\n', logCssColors, str);
    },
    logAllEnv: function logAllEnv(str) {
      console.log('%c%s', logCssColors, str);
    },
    logError: function logError(str) {
      if (isProd) return;
      console.log('%c%s', logCssErrorColors, str);
    },
    logErrorAllEnv: function logErrorAllEnv(str) {
      console.log('%c%s', logCssErrorColors, str);
    },
    logErrorNewline: function logErrorNewline(str) {
      if (isProd) return;
      console.log('%c%s\n', logCssErrorColors, str);
    }
  };
};
export var getColorPreset = function getColorPreset(initials) {
  var _a;
  var initialLowered = (_a = initials[0]) === null || _a === void 0 ? void 0 : _a.toLowerCase();
  var presetColor = avatarColorPresets.find(function (p) {
    return p.group.includes(initialLowered);
  });
  var selectedColor = presetColor ? presetColor.color : avatarColors[Math.floor(Math.random() * avatarColors.length)];
  return selectedColor;
};
export var getZeroPadding = function getZeroPadding(num, places) {
  return String(num).padStart(places, '0');
};
export var getListLengthAbsolute = function getListLengthAbsolute() {
  function getListLength(list) {
    if (Array.isArray(list) && list.length > 0) {
      return list.length;
    }
  }
  function getListLengthWithOwner(list) {
    return getListLength(list) - 2 || 0;
  }
  function getListLengthWithoutOwner(list) {
    return getListLength(list) - 1 || 0;
  }
  function getListLengthActual(list) {
    return getListLength(list) || 0;
  }
  return {
    getListLengthWithOwner: getListLengthWithOwner,
    getListLengthWithoutOwner: getListLengthWithoutOwner,
    getListLengthActual: getListLengthActual
  };
};
export var getSpacedText = function getSpacedText(text) {
  var result = text.replace(/([A-Z])/g, " $1");
  var upperCased = result.charAt(0).toUpperCase() + result.slice(1);
  return upperCased;
};
export var sortObjectsListByIdentifier = function sortObjectsListByIdentifier(list, objectIdentifier) {
  return list.sort(function (objectHead, objectTail) {
    return objectHead[objectIdentifier] < objectTail[objectIdentifier] ? -1 : objectHead[objectIdentifier] > objectTail[objectIdentifier] ? 1 : 0;
  });
};
export var preventKeyControlsForDropdown = function preventKeyControlsForDropdown(keyCode) {
  var keysCode = [13, 9, 37, 38, 39, 40];
  if (keysCode.includes(keyCode)) {
    return false;
  } else {
    return true;
  }
};
export var getAddressAsSingleString = function getAddressAsSingleString(address) {
  var line1 = address.addressLine1 ? address.addressLine1 : "";
  var line2 = " " + (address.addressLine2 ? address.addressLine2 : "");
  var city = " " + (address.city ? address.city : "");
  var state = " " + (address.state ? address.state : "");
  var country = " " + (address.country ? address.country : "");
  var postalCode = " " + (address.postalCode ? address.postalCode : "");
  var addressArray = [line1, line2, city, state, country, postalCode];
  return addressArray.filter(function (a) {
    return a && a != ' ';
  }).join();
};
var _toCamelCase = function toCamelCase(pascalCaseObject) {
  var newObject, origKey, newKey, value;
  if (pascalCaseObject instanceof Array) {
    return pascalCaseObject.map(function (value) {
      if (_typeof(value) === "object") {
        value = _toCamelCase(value);
      }
      return value;
    });
  } else {
    newObject = {};
    for (origKey in pascalCaseObject) {
      if (Object.prototype.hasOwnProperty.call(pascalCaseObject, origKey)) {
        newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
        value = pascalCaseObject[origKey];
        if (value instanceof Array || value !== null && value.constructor === Object) {
          value = _toCamelCase(value);
        }
        newObject[newKey] = value;
      }
    }
  }
  return newObject;
};
export { _toCamelCase as toCamelCase };
export var generateMonthlyDataByYear = function generateMonthlyDataByYear(monthlyData) {
  function getBarColor(invoiceStatus) {
    return InvoiceStatusType.Paid == invoiceStatus ? paidInvoiceColor : unpaidInvoiceColor;
  }
  function getCurrencyCode() {
    var _a, _b;
    return (_b = (_a = monthlyData === null || monthlyData === void 0 ? void 0 : monthlyData[0]) === null || _a === void 0 ? void 0 : _a.currencyCode) === null || _b === void 0 ? void 0 : _b.toUpperCase();
  }
  var chartDataArr = [['Month', "Amount (".concat(getCurrencyCode(), ")"), {
    role: 'style '
  }]];
  months.map(function (m) {
    var _a, _b, _c, _d;
    chartDataArr.push([m.title, ((_b = (_a = monthlyData.filter(function (md) {
      return md.month == m.value;
    })) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.amount) || 0, getBarColor((_d = (_c = monthlyData.filter(function (md) {
      return md.month == m.value;
    })) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.status) || unpaidInvoiceColor]);
  });
  return chartDataArr;
};
export var clearDateTimePicker = function clearDateTimePicker(target) {
  var datePickerResetEvent = new MouseEvent('click', {
    bubbles: false,
    cancelable: false
  });
  var datePickerResetButton = target.querySelector('.vdpr-datepicker__button-reset');
  var datePickerSwitchButton = target.querySelector('.vdpr-datepicker__switch');
  if (datePickerResetButton) datePickerResetButton.dispatchEvent(datePickerResetEvent);
  if (datePickerSwitchButton) {
    var timeoutID = setTimeout(function () {
      datePickerSwitchButton.dispatchEvent(datePickerResetEvent);
      clearTimeout(timeoutID);
    });
  }
};
export var initializeDateTimePlaceholders = function initializeDateTimePlaceholders() {
  var dInputStartDate = document.getElementsByClassName("vdpr-datepicker__calendar-input-date-elem")[0];
  if (dInputStartDate) dInputStartDate.placeholder = "DD/MM/YYYY";
  var dInputEndDate = document.getElementsByClassName("vdpr-datepicker__calendar-input-date-elem")[1];
  if (dInputEndDate) dInputEndDate.placeholder = "DD/MM/YYYY";
  var dInputStartTime = document.getElementsByClassName("vdpr-datepicker__calendar-input-time-elem")[0];
  if (dInputStartTime) dInputStartTime.placeholder = "HH:MM";
  var dInputEndTime = document.getElementsByClassName("vdpr-datepicker__calendar-input-time-elem")[1];
  if (dInputEndTime) dInputEndTime.placeholder = "HH:MM";
};
export var sleep = function sleep(milliseconds) {
  return new Promise(function (resolve) {
    var timeoutID = setTimeout(function () {
      return resolve(timeoutID);
    }, milliseconds);
  });
};
var replaceAllDoubleNewLinesWithSingle = function replaceAllDoubleNewLinesWithSingle(text) {
  return text.replace(/\n{2,}/g, '\n');
};
export var htmlToText = function htmlToText(htmlString) {
  if (!htmlString || typeof htmlString !== 'string') {
    console.error('Invalid input: not a string');
    return '';
  }
  var tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;
  var textOutput = '';
  var _extractText = function extractText(element) {
    return Array.from(element.childNodes).map(function (node) {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.nodeName === 'BR') {
          return ' ';
        } else if (node.nodeName === 'P') {
          return _extractText(node) + '\n';
        } else if (node.nodeName === 'A') {
          return node.textContent;
        }
        return _extractText(node);
      }
      return '';
    }).join('').trim();
  };
  textOutput = replaceAllDoubleNewLinesWithSingle(_extractText(tempDiv));
  return textOutput.trim();
};
export var textToHtml = function textToHtml(plainText) {
  if (!plainText || typeof plainText !== 'string') {
    return '';
  }
  var urlRegex = /(\b(https?|ftp):\/\/[^\s/$.?#].[^\s]*|\bwww\.[^\s/$.?#].[^\s]*|\b[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}\b|\b(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}(?:\/[^\s]*)?)/gi;
  var htmlText = plainText.replace(urlRegex, function (url) {
    var formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : "http://".concat(url);
    return "<a href=\"".concat(formattedUrl, "\" rel=\"noopener noreferrer\" target=\"_blank\">").concat(url, "</a>");
  });
  htmlText = htmlText.replace(/\r?\n/g, '[[NEWLINE]]');
  var paragraphs = htmlText.split('[[NEWLINE]]').map(function (paragraph) {
    return paragraph.trim();
  }).filter(function (paragraph) {
    return paragraph.length > 0;
  });
  htmlText = paragraphs.map(function (paragraph) {
    return "<p>".concat(paragraph, "</p>");
  }).join('');
  return htmlText;
};